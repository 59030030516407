<template>
    <div class="devide-box page-container flex-column">
        <el-form class="searchForm" ref="searchForm" :model="searchData" :inline="true" @submit.native.prevent>
            <el-form-item label="作者：" v-if="isSuperMan">
                <el-input style="width:200px;" v-model="searchData.authorname" clearable placeholder="作者，支持模糊搜索"></el-input>
                <el-button type="primary" @click="doSearchData">查询</el-button>
            </el-form-item>
            <el-form-item style="float:right;">
                <el-button @click="doExportData">导出</el-button>
            </el-form-item>
        </el-form>
        <el-table
            v-loading="loading"
            element-loading-text="数据加载中"
            class="devide-box-table"
            ref="table"
            :data="tableList"
            border
            @sort-change="sortChange"
            style="width: 100%"
        >
            <template slot="empty">
                <div class="emptyData">暂无数据</div>
            </template>
            <el-table-column min-width="100" sortable="custom" prop="count_date" label="统计时间" align="center"></el-table-column>
            <el-table-column min-width="80" sortable="custom" v-if="dynamicShow.author" prop="author_name" label="作者" align="center"></el-table-column>
            <el-table-column prop="sharecropping" sortable="custom" label="付费分成收入" align="center"></el-table-column>
            <el-table-column v-if="false" prop="award_price" sortable="custom" label="优质合作方奖励" align="center">
                <template slot="header">
                    <div class="cell custom_cell">
                        优质合作方奖励
                        <el-tooltip content="优质合作方奖励是小明太极旗下平台给予漫画内容提供方的一项激励制度，平台运营部门会根据每个合作方全部作品当月合计获得赞赏金额、平均人气排名、作品版权性质、持续合作时长等多个因素综合确定每个合作方的补贴金额。小明太极鼓励合作方长期稳定的与我们合作，积极配合平台运营部门的运营活动，并与小明太极版权部门开展更深入的合作" placement="top">
                            <i class="el-icon-question"></i>
                        </el-tooltip>
                    </div>
                </template>
                <template slot-scope="scope">
                    <div class="cell">{{ scope.row.award_price }}</div>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="raise_price" sortable="custom" label="供养分成" align="center"></el-table-column> -->
            <el-table-column prop="all_price" sortable="custom" label="实发分成" align="center"></el-table-column>
        </el-table>
        <paginationPlus
            layout="total, prev, pager, next, jumper"
            :currentPage.sync="pagination.pageIndex"
            :pageSize.sync="pagination.pageSize"
            :total="pagination.total"
            @callback="pageChangeHandle"
        >
        </paginationPlus>
    </div>
</template>
<script>

import { searchMixin, paginationMixin } from '@/mixins';
import { mapState } from 'vuex';

export default {
    mixins: [searchMixin, paginationMixin],
    data() {
        return {
            searchData: {
                authorname: null,
                orderby: 'count_date,1' // 排序  count_date,1  1：倒序，0：正序
            },
            tableList: [],
            dynamicShow: { // 列表显隐字段
                author: false // 作者
            },
            loading: false // 加载中
        };
    },
    created() {
        this.pagination.pageSize = 100;
        this.searchHandle();
    },
    computed: {
        ...mapState('app', {
            level: state => state.userInfo.level // 1：用户 2：管理 3：超管 4:一审人员
        }),
        isSuperMan() { // 是否超管
            return this.level === 3;
        }
    },
    methods: {
        // 导出数据
        doExportData() {
            this.searchHandle(true);
        },
        // 查询数据
        doSearchData() {
            this.searchHandle();
        },
        // 搜索, 广播查询到子组件
        searchHandle(isExport) {
            this.pagination.pageIndex = 1;
            this.getTableList(isExport);
        },
        // 查询列表
        getTableList(isExport) {
            const searchData = JSON.parse(JSON.stringify(this.searchData));
            const params = { ...searchData, ...this.pagination };
            if (isExport) {
                params.isdownload = 1;
                this.downloadData(params);
                return;
            }

            this.loading = true;
            return this.$api('getAllIncome', params).then(res => {
                this.$refs.table && (this.$refs.table.bodyWrapper.scrollTop = 0);
                const { rows = [], pageIndex, pageSize, total } = res.data;
                this.tableList = rows;
                this.pagination.pageIndex = pageIndex;
                this.pagination.pageSize = pageSize;
                this.pagination.total = total;
                this.showDynamicColumn();
                this.transDataShow();
                this.loading = false;
            });
        },
        showDynamicColumn() { // 动态显隐字段
            this.dynamicShow.author = this.isSuperMan && (this.searchData.authorname);
        },
        downloadData(params) { // 导出数据
            const url = '/api/income/getallincome?';
            const nodes = [];
            Object.keys(params).forEach(key => {
                const content = params[key];
                if (!content) {
                    return;
                }
                nodes.push(`${key}=${params[key]}`);
            });
            window.open(url + nodes.join('&'));
        },
        sortChange(v) {
            this.showDynamicColumn();
            this.pagination.pageIndex = 1;
            const order = v.order;
            const prop = v.prop;
            if (order && this.checkHasSort(prop)) {
                this.searchData.orderby = prop + ',' + (order === 'ascending' ? 0 : 1);
            } else {
                this.searchData.orderby = '';
            }
            this.getTableList();
        },
        checkHasSort(prop) { // 检查是否有对应排序项目，没有则不传sort
            switch (prop) {
            case 'cartoon_name':
                return this.dynamicShow.comicName;
            case 'author_name':
                return this.dynamicShow.author;
            case 'product_id':
                return this.dynamicShow.productLine;
            default:
                return true;
            }
        },
        transDataShow() { // 列表数据转换显示
            if (this.tableList.length === 0) {
                return;
            }
            this.tableList.forEach((row) => {
                // row.sharecropping = this.$utils.showIntDesc(row.sharecropping);
                // row.raise_price = this.$utils.showIntDesc(row.raise_price);
                // row.award_price = this.$utils.showIntDesc(row.award_price);
                // row.all_price = this.$utils.showIntDesc(row.all_price);
            });
        },
        // 分页
        pageChangeHandle({ page, size }) {
            this.getTableList();
        }
    }
};
</script>
<style lang="scss">
    .devide-box{
        .custom_cell{
            display: inline-block;
            vertical-align: middle;
            padding: 0;
        }
        &-table{
            flex-grow: 1;
            .el-table__body-wrapper{
                height: calc(100% - 40px);
                position: relative;
                overflow-y: auto;
            }
            tr:last-child {
                font-weight: bold;
            }
        }
        .emptyData{
            color: white;
        }
    }
</style>
